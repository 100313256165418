import axios from "axios"
import { API_ROOT_URL } from "../requests"


export const fetchMedications = async ()  => {
  const url = `${API_ROOT_URL}/public/medications`
  const { data: medications } = await axios.get(url)
  return { type: "medications", payload: medications }
}

export const filterMedications = (filteredMedications) =>  {
  return ({ type: "filteredMedications", payload: filteredMedications })
}
