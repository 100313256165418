import React from "react";
import { If, Else, Then } from "react-if";
import NiceModal, { useModal, muiDialog } from "@ebay/nice-modal-react";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

import useTranslation from "../hooks/use_translation"
import { useSelector } from "react-redux";
import LanguagesPicker from "./languages_picker";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
      width: "280px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
  },
  select: {
    width: "100%",
  },
  loading: {
    margin: "26px auto",
  },
}));

const LanguageSwitcherModal = NiceModal.create(() => {
  const classes = useStyles();
  const modal = useModal();
  const configurationLoading = useSelector(state => state.configurationLoading)
  const { t } = useTranslation();

  return (
    <Dialog {...muiDialog(modal)} className={classes.dialog}>
      <DialogTitle>{t("changeLanguage")}</DialogTitle>
      <DialogContent className={classes.content}>
        <If condition={configurationLoading}>
          <Then>
            <CircularProgress size={60} className={classes.loading} />
          </Then>
          <Else>
            <LanguagesPicker />
          </Else>
        </If>
      </DialogContent>
    </Dialog>
  );
});

export default LanguageSwitcherModal;
