import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { createAuth } from "../actions/auth"
import store from "../utils/store"
import { fetchMedications } from "../actions/medications"
import { updateUserInfo } from "../actions/users"

class DataLoader extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    auth: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      token: PropTypes.string,
      user: PropTypes.object,
    }),
  }

  static defaultProps = { auth: null }

  static getDerivedStateFromProps(props, state) {
    const newState = {}
    if (!state.fetchedData && props.auth) {
      store.dispatch(updateUserInfo())
      newState.fetchedData = true
    }
    if (state.fetchedData && !props.auth) {
      newState.fetchedData = false
    }
    return newState
  }

  componentDidMount() {
    store.dispatch(fetchMedications())
  }

  constructor(props) {
    super(props)
    const token = window.localStorage.getItem("token")
    this.state = { fetchedData: false }
    if (token) {
      const user = JSON.parse(window.localStorage.getItem("user"))
      store.dispatch(createAuth(user, token))
    }
  }

  render() {
    return this.props.children
  }
}

export default connect(({ auth }) => ({ auth }))(DataLoader)
