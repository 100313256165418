import humps from "humps"


export const API_ROOT_URL = window.apiUrl || process.env.PORTAL_BACKEND_URL


export function buildQueryParamsFromObject(paramsObj, decamelize = true) {
  const queryString = Object.entries(paramsObj).reduce((partialQueryString, [key, value]) => {
    key = decamelize ? humps.decamelize(key) : key
    if (value) {
      return `${partialQueryString}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    }
    return partialQueryString
  }, "")
  return queryString
}
