import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Redirect, useHistory } from "react-router-dom"
import { useInterval } from "../hooks/use_interval"
import { logout } from "../actions/auth"
import { buildContinueWithDestination } from "../utils/url"


const isOldActivity = () => {
  const oneHour = 60 * 60 * 1000
  const today = new Date().toDateString()
  const loginDate = window.localStorage.getItem("login_date")
  const timeSinceLastAction = new Date().getTime() - localStorage.getItem("last_action")
  return timeSinceLastAction > oneHour || loginDate !== today
}


const ProtectedRoute = (props) => {
  const histroy = useHistory()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const loginIfNeeded = useCallback(() => {
    if (isOldActivity() || !auth) {
      dispatch(logout())
    }
  }, [histroy, auth, isOldActivity])

  useEffect(loginIfNeeded, [loginIfNeeded])
  useInterval(loginIfNeeded, 1000 * 60)

  if (!auth) {
    return <Redirect {...props} to={buildContinueWithDestination()} />
  }
  return <Route {...props} />
}


export default ProtectedRoute
