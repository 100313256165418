import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import ErrorBoundary from "../utils/error_boundary"
import ProtectedRoute from "./protected_route"
import AnonymousRoute from "./anonymous_route"


const retry = (fn, totalRetries = 7, retriesDone = 0, interval = 500) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (totalRetries === retriesDone) {
            reject(error)
            return
          }
          retry(fn, retriesDone + 1, interval).then(resolve, reject)
        }, (retriesDone + 1) * interval)
      })
  })
}

const LoadableHome = React.lazy(() => retry(() => import("../pages/home")))
const Home = props => <ErrorBoundary><LoadableHome {...props} /></ErrorBoundary>
const LoadableLogin = React.lazy(() => retry(() => import("../pages/login")))
const Login = props => <ErrorBoundary><LoadableLogin {...props} /></ErrorBoundary>
const LoadableCohortSignup = React.lazy(() => retry(() => import("../pages/cohort_signup")))
const CohortSignup = props => <ErrorBoundary><LoadableCohortSignup {...props} /></ErrorBoundary>
const LoadableLeaveTheResearch = React.lazy(() => retry(() => import("../pages/leave_the_research")))
const LeaveTheResearch = props => <ErrorBoundary><LoadableLeaveTheResearch {...props} /></ErrorBoundary>
const LoadableMedicalFiles = React.lazy(() => retry(() => import("../pages/medical_files")))
const MedicalFiles = props => <ErrorBoundary><LoadableMedicalFiles {...props} /></ErrorBoundary>
const LoadableQuestionnaire = React.lazy(() => retry(() => import("../pages/questionnaire")))
const Questionnaire = props => <ErrorBoundary><LoadableQuestionnaire {...props} /></ErrorBoundary>
const LoadableMeeting = React.lazy(() => retry(() => import("../pages/meeting")))
const Meeting = props => <ErrorBoundary><LoadableMeeting {...props} /></ErrorBoundary>
const LoadableTfa = React.lazy(() => retry(() => import("../pages/tfa")))
const Tfa = props => <ErrorBoundary><LoadableTfa {...props} /></ErrorBoundary>
const LoadableEditDetails = React.lazy(() => retry(() => import("../pages/edit_details")))
const EditDetails = props => <ErrorBoundary><LoadableEditDetails {...props} /></ErrorBoundary>
const LoadableUseSupportedBrowser = React.lazy(() => retry(() => import("../pages/use_supported_browser")))
const UseSupportedBrowser = props => <ErrorBoundary><LoadableUseSupportedBrowser {...props} /></ErrorBoundary>
const LoadableSignup = React.lazy(() => retry(() => import("../pages/signup")))
const Signup = props => <ErrorBoundary><LoadableSignup {...props} /></ErrorBoundary>
const LoadableUserReports = React.lazy(() => retry(() => import("../pages/user_reports")))
const UserReports = props => <ErrorBoundary><LoadableUserReports {...props} /></ErrorBoundary>
    

const Router = () => (
  <Switch>
    <Route exact path="/use-supported-browser" component={UseSupportedBrowser} />
    <AnonymousRoute exact path="/login" component={Login} />
    <AnonymousRoute exact path="/signup" component={Signup} />
    <AnonymousRoute exact path="/signup/:cohortSlug" component={CohortSignup} />
    <AnonymousRoute exact path="/tfa" component={Tfa} />
    <ProtectedRoute exact path="/survey" component={Questionnaire} />
    <ProtectedRoute exact path="/medical-files/:fileType" component={MedicalFiles} />
    <ProtectedRoute exact path="/user-blood-tests" render={() => <Redirect to="/medical-files/blood-tests" />} />
    <ProtectedRoute exact path="/leave-the-research" component={LeaveTheResearch} />
    <ProtectedRoute path="/scheduling" component={Meeting} />
    <ProtectedRoute path="/meeting" component={Meeting} />
    <ProtectedRoute exact path="/edit-details" component={EditDetails} />
    <ProtectedRoute exact path="/user-reports" component={UserReports} />
    <ProtectedRoute path="/home" component={Home} />
    <Route path="*" render={() => <Redirect to="/home" />} />
  </Switch>
)

export default Router
