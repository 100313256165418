import React from "react"

import { Mobile } from "../components/device"
import { makeStyles } from "@material-ui/styles"
import AppLogo from "./app_logo"

const useClasses = makeStyles({
  logo: {
    height: 35,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})


const AppHeaderCenter = () => {
  const classes = useClasses()

  return (
    <Mobile>
      <AppLogo className={classes.logo} />
    </Mobile>
  )
}

AppHeaderCenter.propTypes = {}

AppHeaderCenter.defaultProps = {}

export default AppHeaderCenter
