import axios from "axios"
import _ from "lodash"
import { API_ROOT_URL } from "../requests"


export const GET_STREETS = "get_street"

export const getStreets = () => async (dispatch) => {
  const response = await axios.get(`${API_ROOT_URL}/streets/`)
  let streets = response.data
  streets = _.orderBy(streets.map(street => ({ label: street[2], value: street[1], city: street[0] })), ["label"])
  dispatch({ type: GET_STREETS, payload: streets })
}
