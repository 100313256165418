import React from "react"
import AppBar from "@material-ui/core/AppBar"

import { mobile, desktop } from "../utils/media_queries"
import { makeStyles } from "@material-ui/styles"
import colors from "../utils/colors"
import AppHeaderSide1 from "./app_header_side_1"
import AppHeaderSide2 from "./app_header_side_2"
import AppHeaderCenter from "./app_header_center"
import useTranslation from "../hooks/use_translation"
import { Helmet } from "react-helmet"

const useClasses = makeStyles({
  header: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [desktop]: { padding: "10px 70px", },
  },
})


const AppHeader = () => {
  const classes = useClasses()
  const { t } = useTranslation()

  return (
    <AppBar
      position="static"
      className={classes.header}
      color="default"
    >
      <Helmet><title>{t("projectName")}</title></Helmet>
      <AppHeaderSide1 />
      <AppHeaderCenter />
      <AppHeaderSide2 />
    </AppBar>
  )
}

AppHeader.propTypes = {}

AppHeader.defaultProps = {}

export default AppHeader
