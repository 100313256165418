import { useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getCurrentStage } from "../utils/participant"


export const sendEvent = (name, parameters) => {
  gtag("event", name, {
    "page_location": window.location.pathname,
    "page_path": window.location.pathname,
    ...parameters,
  })
}


export const GoogleAnalytics = () => {

  const location = useLocation()
  const user = useSelector(state => state.auth?.user)
  const stage = user && getCurrentStage(user)
  const queryParams = new URLSearchParams(window.location.search)
  const googleAnalyticsId = queryParams.get("ga_user_id") || user?.googleAnalyticsId
  const researchStage = queryParams.get("research_stage") || stage?.name
  const cohort = user?.cohort?.name

  const configureGoogleAnalytics3 = useCallback(() => {
    gtag("config", "UA-126830690-2", {
      "send_page_view": false,
      "cookie_flags": "secure;samesite=none",
      "custom_map": {
        "dimension1": "research_stage",
        "dimension2": "cohort",
        "dimension3": "is_previliged",
      },
      "user_id": googleAnalyticsId,
      "research_stage": researchStage,
      "cohort": cohort,
    })
  }, [googleAnalyticsId, researchStage, cohort])

  const configureGoogleAnalytics4 = useCallback(() => {
    gtag("config", "G-6NHVFJLYZM", {
      "send_page_view": false,
      "cookie_flags": "secure;samesite=none",
      "user_id": googleAnalyticsId,
      "research_stage": researchStage,
      "research_stage_year": stage && stage.index,
    })
    gtag("set", "user_properties", {
      "cohort": cohort,
      "user_id_dimension": googleAnalyticsId,
    })
  }, [googleAnalyticsId, researchStage, cohort])

  const sendPageView = useCallback(() => {
    sendEvent("page_view", {
      "page_location": location.pathname,
      "page_path": location.pathname,
    })
  }, [location.pathname])

  useEffect(configureGoogleAnalytics3, [configureGoogleAnalytics3])
  useEffect(configureGoogleAnalytics4, [configureGoogleAnalytics4])
  useEffect(sendPageView, [sendPageView])

  return null
}

export default GoogleAnalytics