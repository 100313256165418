import React from "react"
import { Link } from "react-router-dom"

import { Desktop } from "../components/device"
import { desktop } from "../utils/media_queries"
import { makeStyles } from "@material-ui/styles"
import AppLogo from "./app_logo"

const useClasses = makeStyles({
  logos: {
    display: "flex",
    alignItems: "center",
    gap: "25px",
    fontSize: 0,
  },
  logo: {
    [desktop]: { height: 55, },
  },
})


const AppHeaderSide1 = () => {
  const classes = useClasses()

  return (
    <div className={classes.logos}>
      <Desktop>
        <Link 
          rel="noopener noreferrer"
          to="/"
          href="/"
        >
          <AppLogo className={classes.logo} />
        </Link>
      </Desktop>
    </div>
  )
}

AppHeaderSide1.propTypes = {}

AppHeaderSide1.defaultProps = {}

export default AppHeaderSide1
