import React from "react"
import { useSelector } from "react-redux"

const AppLogo = ({...props}) => {
  let logoUrl = useSelector(s => s.configuration?.logoUrl)
  const signupCohort = useSelector(s => s.signupCohort)
  if(signupCohort?.logoUrl) {
    logoUrl = signupCohort.logoUrl
  }
 
  return <>
    {logoUrl && <img
      src={logoUrl}
      alt="logo"
      {...props}
    />}
  </>
}

AppLogo.propTypes = {}

AppLogo.defaultProps = {}

export default AppLogo
