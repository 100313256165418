import React from "react";
import LanguageIcon from "@material-ui/icons/Language";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import NiceModal from "@ebay/nice-modal-react";

import { Desktop } from "../components/device";
import LanguageSwitcherModal from "./language_switcher_modal";

const useClasses = makeStyles({
  button: {
    padding: "3px",
  },
  icon: {
    fontSize: "30px",
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    minHeight: "min-content",
    alignItems: "flex-start",
  },
});

const ChangeLanguageButton = () => {
  const classes = useClasses();
  const user = useSelector((state) => state.auth?.user);
  const languages = useSelector(state => state.configuration?.languages)
  const portalMultiLanguages = languages?.length > 1

  if (!portalMultiLanguages) {
    return null;
  }

  const changeLanguage = () => {
    NiceModal.show(LanguageSwitcherModal);
  };

  return (
    <Desktop>
      <IconButton className={classes.button} onClick={changeLanguage}>
        <LanguageIcon className={classes.icon} />
      </IconButton>
    </Desktop>
  );
};

export default ChangeLanguageButton;
