import axios from "axios"
import humps from "humps"
import { buildContinueWithDestination } from "../utils/url"

export const init = () => {

  window.apiUrl = window.apiUrl || process.env.PORTAL_BACKEND_URL


  axios.interceptors.response.use((response) => {
    if (response.data) {
      response.data = humps.camelizeKeys(response.data)
    }
    return response
  }, (error) => {
    const { response } = error
    if (response?.data) {
      error.response.data = humps.camelizeKeys(error.response.data)
    }
    if (error?.config?.url) {
      const url = new URL(error.config.url)
      const token = window.localStorage.getItem("token")
      if (response?.status === 401 && url.origin === window.apiUrl && token) {
        localStorage.clear()
        window.location = buildContinueWithDestination()
        
      }
    }
    return Promise.reject(error)
  })


  axios.interceptors.request.use((config) => {
    if (config.url.startsWith(window.apiUrl)) {
      if (config.data) {
        config.data = humps.decamelizeKeys(config.data)
      }
      const token = window.localStorage.getItem("token")
      if (token) {
        config.headers.common.Authorization = token
      }
    }
    return config
  })

}


export const queryString = (params) => {
  return new URLSearchParams(humps.decamelizeKeys(params)).toString()
}
