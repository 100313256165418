import React from "react"
import { useDispatch, useSelector } from "react-redux"
import HelpIcon from "@material-ui/icons/Help"
import MuiMenu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import classNames from "classnames"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import IconMenu from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import NiceModal from "@ebay/nice-modal-react";


import { Mobile, Desktop } from  "../components/device"
import { logout } from "../actions/auth"
import ErrorBoundary from "../utils/error_boundary"
import useTranslation from "../hooks/use_translation"
import UserConfirmDialog from "../molecules/user_confirm_dialog"
import Hr from "../atoms/hr"
import { makeStyles } from "@material-ui/core"
import { useState } from "react"
import { useHistory } from "react-router"
import LanguageSwitcherModal from "../molecules/language_switcher_modal"
import { useReduxState } from "../hooks/use_redux_state"
import { useSupportUrl } from "../hooks/use_support_url"

const useClasses = makeStyles({
  root: { width: "100%" },
  indicator: { width: "5px" },
  arrowDropDown: { fill: "#bbb" },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    minHeight: "min-content",
    alignItems: "flex-start",
  },
  linkMenuItem: {
    marginLeft: "5px",
    "&:link,&:visited": {
      color: "inherit",
      textDecoration: "inherit", 
    },
  },
  menuUser: {
    display: "flex",
    flexDirection: "column",
    minHeight: "min-content",
    alignItems: "flex-start",
    padding: "11px 33px 11px 11px",
    "&:focus" : { outline: "none" } 
  },
  userNameText: {
    fontSize: "18px",
    color: "#777",
    fontWeight: "700",
  },
  userPhoneText: {
    fontSize: "16px",
    color: "#ccc",
    fontWeight: "300",
  },
})

const Menu = () => {
  const classes = useClasses()
  const { t } = useTranslation()
  const [currentLanguageCode, setCurrentLanguageCode] = useReduxState({ key: "currentLanguageCode" })
  const configuration = useSelector(state => state.configuration)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()

  const dispatch = useDispatch()
  const supportUrl = useSupportUrl()
  const user = useSelector(s => s.auth?.user)
  const hasAuth = Boolean(user)
  const groups = user?.groups || {}
  const portalMultiLanguages = configuration?.languages?.length > 1
  const reportsEnabled = configuration?.reportsEnabled
  
  const closeLogoutDialog = () => {
    setIsDialogOpen(false)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const handleMenu = (event) => {
    setIsMenuOpen(true)
    setAnchorEl(event.currentTarget)
  }


  const onClick = () => {
    closeLogoutDialog()
    dispatch(logout())
    setCurrentLanguageCode(currentLanguageCode)
    gtag("event", "user_logout")
  }

  
  const handleMenuItem = (event) => {
    const value = event.currentTarget.getAttribute("value")
    if (value === "logout") {
      closeMenu()
      setIsDialogOpen(true)
      return
    }
    if (value === "language") {
      closeMenu()
      NiceModal.show(LanguageSwitcherModal);
      return
    }
    history.push(`/${value}`)
    closeMenu()
  }
  
  const userName = `${user?.details?.firstName} ${user?.details?.lastName}`

  return (
    <ErrorBoundary>
      <IconButton
        aria-owns={isMenuOpen ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Desktop>
          <ArrowDropDown className={classes.arrowDropDown} automation-name="menu-icon" />
        </Desktop>
        <Mobile>
          <IconMenu />
        </Mobile>
      </IconButton>
      <MuiMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorPosition={{ top: 200, left: 800 }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {hasAuth && (
          <div className={classes.menuUser}>
            <span className={classes.userNameText}>{userName}</span>
            <span className={classes.userPhoneText}>{user.details?.phone}</span>
            <span className={classes.userPhoneText}>{user.id}</span>
          </div>
        )}
        {hasAuth &&  <Hr />}
        <Mobile>
          <MenuItem className={classes.menuItem} onClick={closeMenu}> 
            <div style={{ display: "flex", flexDirection: "row" }} className={classes.menuItem}>
              <HelpIcon className={classes.helpIcon} />
              <a
                rel="noopener noreferrer" 
                target="_blank" 
                href={supportUrl}
                className={classNames(classes.linkMenuItem, classes.menuItem)}
              >
                {t("help")}
              </a>
            </div>
          </MenuItem>
          {portalMultiLanguages && <MenuItem automation-name="menu-item-language" className={classes.menuItem} value="language" onClick={handleMenuItem}>{t("changeLanguage")}</MenuItem>}
        </Mobile>
        {!hasAuth && <MenuItem automation-name="menu-item-signup" className={classes.menuItem} value="signup" onClick={handleMenuItem}>{t("signup")}</MenuItem> }
        {!hasAuth && <MenuItem automation-name="menu-item-login" className={classes.menuItem} value="login" onClick={handleMenuItem}>{t("login")}</MenuItem> }
        {hasAuth && <MenuItem automation-name="menu-item-home" className={classes.menuItem} value="home" onClick={handleMenuItem}>{t("home")}</MenuItem>}
        {(hasAuth && reportsEnabled) && (
          <MenuItem className={classes.menuItem} value="user-reports" onClick={handleMenuItem} automation-name="menu-item-reports">
            {t("myReports")}
          </MenuItem>
        )}
        {hasAuth && <MenuItem 
         automation-name="menu-item-edit-details"
          className={classes.menuItem} 
          value="edit-details" 
          onClick={handleMenuItem}
        >
          {t("updateDetails")}
        </MenuItem>}
        {hasAuth && <MenuItem className={classes.menuItem} value="logout" onClick={handleMenuItem} automation-name="logout">{t("logout")}</MenuItem>}
      </MuiMenu>
      <UserConfirmDialog
        isDialogOpen={isDialogOpen}
        title={t("logout")}
        body={t("areYouSureYouWantToLeave")}
        textOk={t("textButtonYesIWantToLogOut")}
        textCancel={t("imStaying")}
        onClick={onClick}
        onClose={closeDialog}
      />
    </ErrorBoundary>
  )
}

export default Menu
