import { combineReducers } from "redux"
import dayjs from "dayjs"

import users from "./reducer_users"
import auth from "./reducer_auth"
import streets from "./reducer_streets"
import participantData from "./reducer_participant_data"
import { AUTH } from "../actions/auth"

const createReducer = (actionType, initialState) => (state = initialState, action) => {
  switch(action.type){
  case actionType:
    return action.payload
  default:
    return state
  }
}

const reducersDefaultValues = {
  treatedMissingFoods: null,
  missingFoods: null, 
  filteredFoods: null,
  filteredMedications: null,
  medications: null,
  call: null,
  selectedCallTab: null,
  inAction: false,
  supportTableRefresh: 0,
  labVisitResearchStage: null,
  participant: null,
  adminReportsData: null,
  signupCohort: null,
  didAnswerAntibioticsQuestion: null,
  minimumVisitDate: null,
  appointmentsSchedluingAvailableShifts: null,
  appointmentsScheduledShift: null,
  appointmentsSchedluingSelectedDate: null,
  appointmentsSchedluingSelectedShift: null,
  shiftCalendarCurrentMonth: dayjs().month(),
  currentLanguageCode: window.defaultLanguage || "he",
  configuration: null,
  configurationLoading: true,
  allCohorts: null,
}

const reducers = {}
Object.entries(reducersDefaultValues).map(([key, defaultValue]) => {
  reducers[key] = createReducer(key, defaultValue)
})

const appReducer = combineReducers({
  users,
  auth,
  streets,
  participantData,
  ...reducers
})

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE" || action.type === AUTH && !action.payload) {
    state = { medications: state.medications }
  }

  return appReducer(state, action)
}

export default rootReducer
