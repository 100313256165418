import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import classNames from "classnames"


const styles = {
  hr: {
    border: 0,
    clear: "both",
    display: "block",
    backgroundColor: "WhiteSmoke",
    height: "1px",
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
  }
}

const Hr = ({ className, classes }) => (
  <hr className={classNames(classes.hr, className)}/>
)

Hr.propTypes = { 
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
}

Hr.defaultProps = { className: null }

export default withStyles(styles)(Hr)
