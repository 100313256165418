import React, { Component } from "react"
import { captureException } from "@sentry/react"
import PropTypes from "prop-types"

import ErrorBoundaryText from "../molecules/error_boundary_text"

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    showErrorMessage: PropTypes.bool,
    notifySentry: PropTypes.bool,
  }

  static defaultProps = { 
    children: null,
    showErrorMessage: true,
    notifySentry: true,
  }

  state = { hasError: false }

  componentDidCatch(err) {
    this.setState({ hasError: true })
    if(!this.props.notifySentry) {
      console.error(err) // eslint-disable-line no-console
      captureException(err)
    }
  }
  render() {
    if (this.state.hasError) {
      if(this.props.showErrorMessage) {
        return <ErrorBoundaryText />
      }
      return (null)
    }
    if (this.props.children) {
      return this.props.children
    }
    return (null)
  }
}

export default ErrorBoundary
