import { createStore, applyMiddleware, compose } from "redux"
import promise from "redux-promise"
import freeze from "redux-freeze"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import reducers from "../reducers"
import { clearLocalStorageIfNeed } from "./local_storage"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reduxMiddlewares = [thunk, promise]
if (window.isDebug) {
  reduxMiddlewares.push(freeze)
}

const persistedReducer = persistReducer({ key: "root", storage, whitelist: ["auth", "config", "currentLanguageCode"] }, reducers)

export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...reduxMiddlewares)))
export const persistor = persistStore(store)

clearLocalStorageIfNeed()
if(localStorage.length === 0) {
  persistor.purge()
}
export default store
