import axios from "axios"
import moment from "moment"

import { API_ROOT_URL } from "."

export const fetchParticipant = async (participantId) => {
  const { data } = await axios.get(`${API_ROOT_URL}/protected/participant`)
  const participant = data
  return participant
}
