import { configureScope as configureSentryScope } from "@sentry/react"

export const AUTH = "AUTH"

export function createAuth(user, token) {
  configureSentryScope(scope => { scope.setUser({ id: user.id, phone: user.details.phone }) })
  localStorage.setItem("token", token)
  localStorage.setItem("user", JSON.stringify(user))
  return {
    type: AUTH,
    payload: { user, token },
  }
}

export function logout() {
  configureSentryScope(scope => { scope.setUser({ phone: null, id: null }) })
  localStorage.clear()
  return {
    type: AUTH,
    payload: null,
  }
}
