import { RESET_PARTICIPANT_DATA } from "../actions/participant"
const INITIAL_STATE = {}


export default (state = INITIAL_STATE, action) => {
  switch(action.type){
  case RESET_PARTICIPANT_DATA:
    return { ...INITIAL_STATE, ...action.payload }
  default:
    return state
  }
}