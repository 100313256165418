import React from "react"
import Fade from "@material-ui/core/Fade"
import CircularProgress from "@material-ui/core/CircularProgress"
import PropTypes from "prop-types"

const Loading = ({ inAction, style, delay }) => (
  <div
    style={{
      position: "absolute",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      zIndex: 1,
      ...(style || {})
    }}
  >
    <Fade
      in={inAction}
      unmountOnExit
      style={{ transitionDelay: inAction ? delay : "0ms", }}
    >
      <CircularProgress />
    </Fade>
  </div>
)
Loading.propTypes = {
  inAction: PropTypes.bool,
  style: PropTypes.object, 
  delay: PropTypes.string,
}

Loading.defaultProps = { inAction: true, delay: "200ms" }


export default Loading
