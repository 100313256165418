import React, { Fragment } from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@material-ui/core/styles"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import jssRtl from "jss-rtl"
import jssPreset from "@material-ui/styles/jssPreset"
import StylesProvider from "@material-ui/styles/StylesProvider"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import NiceModal from '@ebay/nice-modal-react'
import { SnackbarProvider } from "notistack"
import { create as jssCreate } from "jss"
import CloseIcon from "@material-ui/icons/Close"
import { PersistGate } from "redux-persist/integration/react"

import DataLoader from "./components/data_loader"
import GoogleAnalytics from "./components/google_analytics"
import colors from "./utils/colors"
import store, { persistor } from "./utils/store"
import Router from "./components/router"
import Loading from "./organism/loading"
import AppHeader from "./organism/app_header"
import I18Provider from "./molecules/i18_provider"

const materialUiTheme = createMuiTheme({
  direction: "rtl",
  palette: { primary: { main: colors.brandBlue } },
  typography: { useNextVariants: true },
})
const jss = jssCreate({ plugins: [...jssPreset().plugins, jssRtl()] })
// const generateClassName = createGenerateClassName()

String.prototype.format = function () {
  var i = 0, args = arguments
  return this.replace(/{}/g, function () {
    return typeof args[i] != "undefined" ? args[i++] : ""
  })
}

Sentry.init({
  dsn: "https://a9186af617f54541819ed5ef90d6ae5c@o120520.ingest.sentry.io/1404915",
  integrations: [new Integrations.BrowserTracing()],
  environment: window.sentryEnvironment || window.env || "unknown",
  ignoreErrors: [
    /request failed with status code (?!(?:500)).*/i,
  ]
})


const App = () =>  (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18Provider>
        <StylesProvider jss={jss}>
          {/* <StylesProvider jss={jss} generateClassName={generateClassName}> */}
          <ThemeProvider theme={materialUiTheme}>
            <SnackbarProvider
              autoHideDuration={2000}
              maxSnack={1}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              action={<CloseIcon key="1" style={{ cursor: "pointer" }} />}
            >
              <DataLoader>
                <BrowserRouter>
                  <Fragment>
                    <AppHeader />
                    <React.Suspense fallback={<Loading />}>
                      <NiceModal.Provider>
                        <Router />
                      </NiceModal.Provider>
                    </React.Suspense>
                    <Sentry.ErrorBoundary>
                      <GoogleAnalytics />
                    </Sentry.ErrorBoundary>
                  </Fragment>
                </BrowserRouter>
              </DataLoader>
            </SnackbarProvider>
          </ThemeProvider>
        </StylesProvider>
      </I18Provider>
    </PersistGate>
  </Provider>
)

export default <App />
