import React from "react"
import HelpOutline from "@material-ui/icons/HelpOutline"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { If, Then, Else } from "react-if"

import Menu from "./menu"
import { Desktop } from "../components/device"
import { makeStyles } from "@material-ui/styles"
import colors from "../utils/colors"
import ChangeLanguageButton from "../molecules/change_language_button"
import useTranslation from "../hooks/use_translation"
import { useSupportUrl } from "../hooks/use_support_url"

const useClasses = makeStyles({

  separator: {
    height: "16px",
    width: "1px",
    marginLeft: "16px",
    marginRight: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  helpLink: {
    fontSize: "18px",
    color: colors.brandAqua,
    fontWeight: "700",
    textDecoration: "none",
    display: "flex",
    "&:hover span": { textDecoration: "underline", },
    "& svg": { marginRight: 10, },
  },
  user: {
    fontSize: "18px",
    color: colors.gray60,
    fontWeight: "400",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }
})


const AppHeaderSide2 = () => {
  const classes = useClasses()
  const auth = useSelector(state => state.auth)
  const user = `${auth?.user?.details?.firstName} ${auth?.user?.details?.lastName}`
  const history = useHistory()
  const supportUrl = useSupportUrl()
  const  { t } = useTranslation()


  return (
    <div className={classes.buttonsContainer}>
      <Desktop>
        <Link
          rel="noopener noreferrer"
          target="_blank"
          to={{ pathname: supportUrl }}
          className={classes.helpLink}>
          <HelpOutline />
          <span>{t("help")}</span>
        </Link>
        <div className={classes.separator} />
        <If condition={auth}>
          <Then>
            <span className={classes.user}>{user}</span>
          </Then>
          <Else>
            <span className={classes.user}>{t("menu")}</span>
          </Else>
        </If>
      </Desktop>
      <Menu />
      <ChangeLanguageButton />
    </div>
  )
}

AppHeaderSide2.propTypes = {}

AppHeaderSide2.defaultProps = {}

export default AppHeaderSide2
