import React, { useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import PropTypes from "prop-types";
import axios from "axios";
import { captureException } from "@sentry/react"
import { useSelector } from "react-redux";

import heLanguage from "../locales/he.json";
import enLanguage from "../locales/en.json";
import arLanguage from "../locales/ar.json";
import { useReduxState } from "../hooks/use_redux_state";
import { API_ROOT_URL } from "../requests"


const bundledResources = {
  en: enLanguage,
  he: heLanguage,
  ar: arLanguage,
};

const localBackend = resourcesToBackend((lng, ns) => bundledResources[lng]);

i18n.use(initReactI18next).use(localBackend).init({
  react: {
    useSuspense: false,
  },
});

const I18Provider = ({ children }) => {
  const currentLanguageCode = useSelector((state) => state.currentLanguageCode);
  const user = useSelector(state => state.auth?.user)
  const [, setConfiguration] = useReduxState({ key: "configuration" })
  const [, setConfigurationLoading] = useReduxState({ key: "configurationLoading" })

  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        setConfigurationLoading(true)
        const { data } = await axios.get(`${API_ROOT_URL}/${user ? "protected" : "public"}/configuration`)
        setConfiguration(data)
      } catch (error) {
        captureException(error)
      } finally{
        setConfigurationLoading(false)
      }
    }
    fetchConfiguration()
  }, [setConfiguration, user])

  useEffect(() => {
    if (currentLanguageCode !== i18n.language) {
        i18n.changeLanguage(currentLanguageCode);
    }
  }, [currentLanguageCode, i18n]);

  return children;
};

I18Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default I18Provider;
