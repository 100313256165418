import React from "react";

import useTranslation from "../hooks/use_translation";

const ErrorBoundaryText = ({ error }) => {
  const { t } = useTranslation();

  return <span>{t("generalError")}</span>;
};

export default ErrorBoundaryText;
