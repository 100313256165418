import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import { getContinueWith } from "../utils/url"


const AnonymousRoute = (props) => {
  const auth = useSelector(state => state.auth)  
  const to = getContinueWith() || '/home'

  if (auth) {
    return <Redirect {...props} to={to} />
  }
  return <Route {...props} />
}


export default AnonymousRoute
