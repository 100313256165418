import ReactDOM from "react-dom"
import bowser from "bowser"

import App from "./app"
import { clearLocalStorageIfNeed } from "./utils/local_storage"
import { init as initAxios } from "./molecules/axios"

import  { unregister as unregisterServiceWorker } from './registerServiceWorker';

unregisterServiceWorker();

window.apiUrl = window.apiUrl || process.env.PORTAL_BACKEND_URL


window.sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


const setLastAction = () => {
  localStorage.setItem("last_action", new Date().getTime())
}
document.onload = setLastAction
document.onclick = setLastAction
document.onkeypress = setLastAction

const setLastRun = () => {
  localStorage.setItem("site_is_running", new Date().getTime())
}
setInterval(setLastRun, 500)

clearLocalStorageIfNeed()

initAxios()

const browserName = bowser.getParser(window.navigator.userAgent).getBrowserName()
if (browserName === "Internet Explorer" && window.location.pathname !== "/use-supported-browser") {
  window.location = "/use-supported-browser"
}

function initApp() {
  ReactDOM.render(
    App,
    document.querySelector(".app-container"),
  )
}

initApp()
