import store from "./store"

export const verifyLocalStorage = () => {
  try {
    localStorage.setItem("private-browser-detection", 1)
    localStorage.removeItem("private-browser-detection")
    return false
  }catch(e){
    Storage.prototype._setItem = Storage.prototype.setItem
    Storage.prototype.setItem = function() {}
  }
}


export const clearLocalStorageIfNeed = () => {
  verifyLocalStorage()
  const loginDate = window.localStorage.getItem("login_date")
  const timeFromLastAction = (new Date().getTime() - localStorage.getItem("last_action"))
  const timeFromSiteIsRunning = (new Date().getTime() - localStorage.getItem("site_is_running"))
  const everyThingIsOk = loginDate === new Date().toDateString()
  && timeFromLastAction < 60 * 60 * 1000
  && timeFromSiteIsRunning < (window.isDebug ? 1000 * 60 : 1000 * 5)
  if (!everyThingIsOk) {
    const currentLanguageCode = JSON.parse(localStorage.getItem("persist:root"))
    localStorage.clear()
    localStorage.setItem("persist:root", JSON.stringify({currentLanguageCode }))
  }
}
