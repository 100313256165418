
import { GET_STREETS } from "../actions/streets"

export default (state = [], action) => {
  switch (action.type) {
  case GET_STREETS:
    return action.payload
  default:
    return state
  }
}
